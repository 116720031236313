
import { defineComponent, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'
import { AxiosResponse } from 'axios'
import { ImportTaskResult } from '@/model/index'
import { useToast } from 'vuestic-ui'

/*

 failureCount: number;
  successCount: number;
  failiureDetails: Array<ImportFailureDetail>;
*/
export default defineComponent({
  setup() {
    name: 'data-export'
    const projectInfoFileInput = ref<HTMLInputElement>()
    const communicationsFileInput = ref<HTMLInputElement>()
    const communicationImprotResult = ref<ImportTaskResult>()
    const projectInfoImprotResult = ref<ImportTaskResult>()
    const importInprogress = ref(false)
    const { init : initToast } = useToast()
    
    function exportCommunications() {
      exportExcel('communication', 'AllCommunications');
    }

    function exportProjectInfos() {
      exportExcel('company', 'AllProjects');
    }

    function exportExcel(dataType: string, filenameWithoutExt: string) {
      axiosService.post(`api/export/${dataType}/download`).then((res: AxiosResponse<string>) => {
        initToast({
          message: '数据准备完成!',
          color: 'success',
          closeable: true,
          duration: 2000
        })
        window.location.href = `${process.env.VUE_APP_API_ENDPOINT}api/export/download?fileGuid=${res.data}&downloadFileNameWithoutExtension=${filenameWithoutExt}`;
      })
    }

    return  {
      projectInfoFileInput,
      communicationsFileInput,
      exportCommunications,
      exportProjectInfos,
      communicationImprotResult,
      projectInfoImprotResult,
      importInprogress,
    }
  },
})
