<template>
<div class="import-container">
  <h2>数据导出</h2>
  <div class="pt-3">
    <div>
      <h5>项目基本信息</h5>
      <hr/>
      <div class="row">
        <div class="flex md12">
          <va-button :rounded="false" class="px-2" @click="exportProjectInfos">导出</va-button>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <h5>项目跟进记录</h5>
      <hr/>
      <div class="row">
        <div class="flex md12">
          <va-button :rounded="false" class="px-2" @click="exportCommunications">导出</va-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'
import { AxiosResponse } from 'axios'
import { ImportTaskResult } from '@/model/index'
import { useToast } from 'vuestic-ui'

/*

 failureCount: number;
  successCount: number;
  failiureDetails: Array<ImportFailureDetail>;
*/
export default defineComponent({
  setup() {
    name: 'data-export'
    const projectInfoFileInput = ref<HTMLInputElement>()
    const communicationsFileInput = ref<HTMLInputElement>()
    const communicationImprotResult = ref<ImportTaskResult>()
    const projectInfoImprotResult = ref<ImportTaskResult>()
    const importInprogress = ref(false)
    const { init : initToast } = useToast()
    
    function exportCommunications() {
      exportExcel('communication', 'AllCommunications');
    }

    function exportProjectInfos() {
      exportExcel('company', 'AllProjects');
    }

    function exportExcel(dataType: string, filenameWithoutExt: string) {
      axiosService.post(`api/export/${dataType}/download`).then((res: AxiosResponse<string>) => {
        initToast({
          message: '数据准备完成!',
          color: 'success',
          closeable: true,
          duration: 2000
        })
        window.location.href = `${process.env.VUE_APP_API_ENDPOINT}api/export/download?fileGuid=${res.data}&downloadFileNameWithoutExtension=${filenameWithoutExt}`;
      })
    }

    return  {
      projectInfoFileInput,
      communicationsFileInput,
      exportCommunications,
      exportProjectInfos,
      communicationImprotResult,
      projectInfoImprotResult,
      importInprogress,
    }
  },
})
</script>
<style lang="scss" scoped>
  .import-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    h5 {
      font-size: .9rem;
      font-weight: 600;
    }
    .va-alert::v-deep {
      .va-alert__content {
        display: inline;
        .num {
          padding: 0.3rem;
          font-size: 1.2rem;
          font-weight: 600;
          &.red {
            color: #ff0000;
          }
          &.success {
            color: #057532;
          }
        }
      }
    }
  }
</style>